<template>
    <div class="google-login" />
</template>

<script>
import API from '@/api';
import jwt from 'jsonwebtoken';
import { v4 as uuidv4 } from 'uuid';
import { mapMutations } from 'vuex';

export default {
    components: {},
    data() {
        return {
            code: '',
            language: 'zhTw',
        };
    },
    computed: {
    },
    created() {
        this.getCode();
    },
    mounted() {},
    methods: {
        ...mapMutations('mNotification', ['handleError']),
        ...mapMutations('mLoading', ['setLoading']),
        getCode() {
            const lang = localStorage.getItem('googleLoginLanguage');
            if (lang) {
                this.language = lang;
            }
            this.code = this.$route.query.code;
            this.setLoading(true);
            API.User.LoginWithSocial('google', { token: this.code })
                .then((res) => {
                    console.log('google login res', res.data);
                    // register: true 尚未註冊
                    const jwtInfo = jwt.decode(res.data.token);
                    if (res.data && res.data.register === true) {
                        this.registerWithGoogle(jwtInfo, res.data.token);
                    } else {
                        this.$router.replace({ path: `/${this.language}/` });
                    }
                    this.setLoading(false);
                })
                .catch((e) => {
                    console.log('error', e);
                    this.handleError({
                        message: e || this.$t('alertMessage.login-fail'),
                        color: 'error',
                    });
                    this.setLoading(false);
                });
        },
        registerWithGoogle(info, socialToken) {
            console.log('registerWithGoogle', info);
            const form = {
                account: info.data.account,
                password: uuidv4(),
                socialToken,
                data: {
                    firstName: info.data.googleName,
                    contactEmail: info.data.account,
                },
            };
            this.setLoading(true);
            API.User.Register(form)
                .then((res) => {
                    console.log('google Register', res);
                    this.$router.replace({ path: `/${this.language}/user/profile` });
                    this.setLoading(false);
                })
                .catch((e) => {
                    console.log('error', e);
                    this.$router.replace({ path: `/${this.language}/login` });
                    if (e.response?.data?.message === '該帳號已註冊') {
                        e.response.data.message = '已有帳號使用相同mail，請使用原帳號登入後綁定 google 登入';
                    }
                    this.handleError({
                        message: e || this.$t('alertMessage.register-fail'),
                        color: 'error',
                    });
                    this.setLoading(false);
                });
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
